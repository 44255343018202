import React, { useState, useEffect } from 'react';
import { string, func } from 'prop-types';
import classNames from 'classnames';
import { Col, Row } from '@thd-olt-component-react/grid';
import { Input } from '@thd-olt-component-react/input';
import { notEmptyString, validDisplayName, validateDisplayName } from '../helper/QAModalFormHelper';

export const DisplayName = ({ formInputClasses, displayName, validationError, setDisplayName }) => {
  const inputClass = classNames({ 'form-input-field--error': validationError });
  const [errorTextForDisplayName, setErrorTextForDisplayName] = useState(null);

  useEffect(() => {
    if (!notEmptyString(displayName)) {
      setErrorTextForDisplayName('Please provide a display name.');
    } else if (!validDisplayName(displayName)) {
      setErrorTextForDisplayName('Display name should have at least 4 characters.');
    } else {
      setErrorTextForDisplayName(null);
    }
  }, [displayName]);

  return (
    <Row>
      <Col nopadding>
        <span className={`${formInputClasses}__title`}>Display Name</span>
        <Input
          value={displayName}
          className={inputClass}
          errorText={errorTextForDisplayName}
          validation={(text) => validateDisplayName(text)}
          maxLength="25"
          onChange={(text) => setDisplayName(text)}
          data-testid="displayNameTestId"
        />
        {validationError && (
          <span data-testid="validationErrorTestId" className="form-input-field--error-message">
            {validationError}
          </span>
        )}
        <p className="displayName">Please do not use your full name, spaces or symbols.</p>
      </Col>
    </Row>
  );
};

DisplayName.displayName = 'DisplayName';

DisplayName.propTypes = {
  formInputClasses: string,
  displayName: string,
  validationError: string,
  setDisplayName: func
};

DisplayName.defaultProps = {
  formInputClasses: null,
  displayName: '',
  validationError: '',
  setDisplayName: null
};
