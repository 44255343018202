import React from 'react';
import { string, number } from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { AccordionBodyComponent } from './Accordion/AccordionBodyComponent';
import { AccordionBodyPlaceholder } from './Accordion/AccordionBodyPlaceholder';
import { QAContextProvider } from '../context/QAContext';
import { dataModel } from './dataModel';
import './QuestionsAndAnswers.scss';

export const QuestionsAndAnswersAccordionBodyComponent = ({ itemId, canonicalUrl, seoPageNumber }) => {
  return (
    <div className="grid questions-and-answers" data-component="QuestionsAndAnswersAccordionBody">
      <div className="qa-wrapper">
        <QAContextProvider itemId={itemId} seoPageNumber={seoPageNumber}>
          <QueryProvider cacheKey="question-answer-accordion-body">
            <AccordionBodyComponent itemId={itemId} />
          </QueryProvider>
        </QAContextProvider>
      </div>
    </div>
  );
};

QuestionsAndAnswersAccordionBodyComponent.displayName = 'QuestionsAndAnswersAccordionBody';

QuestionsAndAnswersAccordionBodyComponent.propTypes = {
  itemId: string.isRequired,
  canonicalUrl: string,
  seoPageNumber: number
};

QuestionsAndAnswersAccordionBodyComponent.defaultProps = {
  canonicalUrl: null,
  seoPageNumber: null
};

QuestionsAndAnswersAccordionBodyComponent.dataModel = dataModel;

const HydratedComponent = withHydrator({
  delay: 200,
  id: 'questions_answers_accordion_body',
  placeholder: <AccordionBodyPlaceholder />,
  scrollBuffer: 0
}, QuestionsAndAnswersAccordionBodyComponent);

const DynamicComponent = withDynamicComponent(HydratedComponent);

export const QuestionsAndAnswersAccordionBody = withErrorBoundary(DynamicComponent);

QuestionsAndAnswersAccordionBody.propTypes = {
  itemId: string.isRequired,
  canonicalUrl: string,
  seoPageNumber: number
};

QuestionsAndAnswersAccordionBody.defaultProps = {
  canonicalUrl: null,
  seoPageNumber: null
};

QuestionsAndAnswersAccordionBody.dataModel = dataModel;
