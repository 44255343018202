import React, { cloneElement, isValidElement, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import { Typography } from '@one-thd/sui-atomic-components';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { dataModel } from '../dataModel';
import { SpecificationGroupContent } from './SpecificationGroupContent';
import { SpecificationsPlaceholder } from './placeholders/SpecificationsPlaceholder';

export const SpecificationsDesktopComponent = (props) => {
  const {
    itemId,
    children,
    oneColumn
  } = props;
  const { channel } = useContext(ExperienceContext);

  const { data, loading, error } = useDataModel('product', { variables: { itemId } });

  if (loading) return <SpecificationsPlaceholder channel="desktop" />;

  if (error && !data) {
    return null;
  }

  if (!data?.product?.specificationGroup) {
    return null;
  }

  const { specificationGroup } = data.product;

  if (!specificationGroup.length) {
    return null;
  }

  return (
    <div
      className="sui-flex sui-flex-wrap sui-w-full box-border sui-p-5 sui-pointer-events-auto"
      id="specifications"
      data-component="SpecificationsDesktop"
    >
      <div className="sui-flex sui-flex-wrap sui-w-full">
        <Typography variant="h2">Specifications</Typography>
        {children && cloneElement(children, {
          itemId,
          maxfeatures: 8,
          minfeatures: 3,
          channel,
          displaySimilarLinks: true
        })}
        <SpecificationGroupContent specificationGroup={specificationGroup} oneColumn={oneColumn} />
      </div>
    </div>
  );
};

SpecificationsDesktopComponent.displayName = 'SpecificationsDesktop';

const propTypes = {
  /** SKU Number */
  itemId: PropTypes.string.isRequired,
  /** Can take an (optional) single child component of type KeyProductFeatures */
  children: (props, propName, componentName) => {
    const prop = props[propName];
    let error;
    if (prop && (!isValidElement(prop) || prop.type.name !== 'KeyProductFeatures')) {
      error = new Error(`'${componentName}' only accepts a single child of type 'KeyProductFeatures'`);
    }
    return error;
  },
  oneColumn: PropTypes.bool,
};
SpecificationsDesktopComponent.propTypes = propTypes;

const defaultProps = {
  children: null,
  oneColumn: false,
};
SpecificationsDesktopComponent.defaultProps = defaultProps;
SpecificationsDesktopComponent.dataModel = dataModel;

const HydratedComponent = withHydrator({
  id: 'specifications-desktop',
  scrollBuffer: 750,
  delay: 1500,
  preserveCtxVal: 'clientStore',
  placeholder: <SpecificationsPlaceholder channel="desktop" />
}, SpecificationsDesktopComponent);
const DynamicComponent = withDynamicComponent(HydratedComponent);

export const SpecificationsDesktop = withErrorBoundary(DynamicComponent);

SpecificationsDesktop.dataModel = dataModel;
SpecificationsDesktop.propTypes = propTypes;
SpecificationsDesktop.defaultProps = defaultProps;
