import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { useProductDeprecated } from '@thd-nucleus/data-sources/react/dataModel/migration';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { Placeholder } from '@thd-olt-component-react/core-ui';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import { MobileOverlay } from '../Mobile/MobileOverlay';
import { useProductReviews } from '../useProductReviews';
import { PresentationProvider } from '../../context/PresentationProvider';
import { dataModel } from '../ReviewDataModel';
import { initialState } from '../../constants';
import { stateReducer } from '../../reducer';
import { RatingsAndReviewsDefaultProps, RatingsAndReviewsPropTypes } from '../RatingsAndReviewsPropTypes';
import { RatingsAndReviewsPlaceholder } from '../RatingsAndReviewsPlaceholder';
import { getProductReviews, onFilterChange } from '../../helpers';
import { FirstReview } from '../FirstReview/FirstReview.component';
import { RatingsAndReviewsDesktop } from '../RatingsAndReviewsDesktop';
import { track } from '../../ratings-reviews-analytics';
import '../ratings-and-reviews.style.scss';

const RatingsAndReviewsAccordionBody = ({
  apiKey, itemId, prosAndCons, seoPageNumber, category = 'product', disableOverlayOnClick
}) => {

  useEffect(() => {
    track('accordion-open');
  }, []);

  const { channel } = useContext(ExperienceContext);
  const isDesktop = channel === 'desktop';
  const [state, dispatch] = useReducer(stateReducer, initialState);
  const persistedStatistics = useRef({});
  const initialReviews = useRef({});
  const persistedPageContext = useRef();
  const carouselRef = useRef();

  const { data, error, loading: productLoading } = useProductDeprecated({
    itemId,
    domains: ['identifiers', 'mediaSlim'],
    skip: category === 'store'
  });

  useEffect(() => {
    if (seoPageNumber) {
      dispatch({ type: 'sort', value: 'photoreview' });
      dispatch({ type: 'pageLoad', value: seoPageNumber });
    }
  }, [seoPageNumber]);

  const {
    averageRating, FilterSelected, loading, reviewModels = [], statistics, totalReviews
  } = useProductReviews({ itemId, seoPageNumber, ...state });

  useEffect(() => {
    if (!persistedPageContext.current) {
      persistedPageContext.current = state.pageContext;
      return;
    }
    if (persistedPageContext.current.currentPage !== state.pageContext.currentPage) {
      persistedPageContext.current = state.pageContext;
      if (carouselRef.current) {
        carouselRef.current.scrollIntoView({
          // do not use smooth. It'll cause the page to scroll to the bottom while it updates the results.
          behavior: 'auto'
        });
      }
    }
  }, [state.pageContext]);

  const {
    filters, pageContext, searchText, selectedSentiment, sortBy
  } = state;
  const hasProductData = averageRating || totalReviews;
  const sentimentChange = (sentiment) => dispatch({ type: 'sentiment', value: sentiment });

  if (loading && !persistedStatistics.current[itemId]) {
    return (
      <RatingsAndReviewsPlaceholder
        color="#E0E0E0"
        showLoadingAnimation
      />
    );
  }
  const selected = selectedSentiment ? [selectedSentiment] : filters.filter((rf) => rf.checked);
  let productReviews = getProductReviews({
    reviewModels, initialReviews, itemId, searchText, selected
  });
  const noFallbacks = !(hasProductData || productReviews || persistedStatistics.current[itemId]);
  if (noFallbacks) {
    return null;
  }
  const updatedTotalpage = selectedSentiment
    ? Math.ceil(selectedSentiment?.TotalResults / 10) || 1
    : statistics?.totalPages || 1;
  if (state.pageContext.totalPages !== updatedTotalpage) {
    dispatch({ type: 'totalPages', value: updatedTotalpage });
  }
  const { SearchText } = FilterSelected || {};
  if (persistedStatistics.current && !persistedStatistics.current[itemId]) {
    persistedStatistics.current[itemId] = statistics;
    initialReviews.current[itemId] = reviewModels;
  }
  const reviewStatistics = persistedStatistics.current && persistedStatistics.current[itemId]
    ? persistedStatistics.current[itemId]
    : statistics;
  const noResults = state.searchText && reviewModels.length === 0 && !loading;

  const { product } = data || {};
  const { identifiers, media } = product || {};
  const { brandName = '', productLabel = '' } = identifiers || {};
  const { image, images } = media || {};
  const { sizes } = (images || []).length ? images[0] : {};
  const imageUrl = !!(image?.url && (sizes || []).length)
    && image.url.replace(/1000/, sizes[0]);

  if (isDesktop) {
    return <RatingsAndReviewsDesktop itemId={itemId} isUsingAccordion disableOverlayOnClick={disableOverlayOnClick} />;
  }

  if (category === 'product'
    && (!(reviewModels?.length || reviewStatistics?.currentSku?.FilteredReviewStatistics?.TotalReviewCount))) {
    return (
      <PresentationProvider useCondensedLayout>
        <FirstReview
          reviewStatistics={reviewStatistics}
          itemId={itemId}
          apiKey={apiKey}
          className="outline"
          showHeading={false}
        />
      </PresentationProvider>
    );
  }

  return (
    <PresentationProvider useCondensedLayout>
      <div className="ratings-reviews__accordion-body-content" data-component="RatingsAndReviewsAccordionBody">
        <MobileOverlay
          apiKey={apiKey}
          itemId={itemId}
          reviewModels={reviewModels}
          onDeselectFilter={(checkbox) => dispatch({ type: 'removeFilter', value: checkbox })}
          onFilterChange={(updatedFilters) => onFilterChange(updatedFilters, { dispatch, useCondensedLayout: true })}
          onPageChange={(updatedPageContext) => dispatch({
            type: 'pageChange',
            value: updatedPageContext
          })}
          filterCheckboxes={filters}
          reviewStatistics={reviewStatistics}
          selected={selected}
          selectedSentiment={selectedSentiment}
          brandName={brandName}
          imageUrl={imageUrl}
          productLabel={productLabel}
          prosAndCons={prosAndCons}
          onSearch={(searchTerm) => {
            if (searchTerm) {
              dispatch({ type: 'search', value: searchTerm });
              dispatch({ type: 'sort', value: 'photoreview' });
            }
          }}
          onSearchClear={() => dispatch({ type: 'search', value: null })}
          onSentimentChange={sentimentChange}
          searchTerm={SearchText}
          onSortChange={(updatedSortValue) => {
            dispatch({ type: 'sort', value: updatedSortValue });
            dispatch({ type: 'search', value: null });
          }}
          sortBy={sortBy}
          noResults={noResults}
          loading={loading}
          pageContext={pageContext}
          statistics={statistics}
          category={category}
          seoPageNumber={seoPageNumber}
          carouselRef={carouselRef}
        />
      </div>
    </PresentationProvider>
  );
};

RatingsAndReviewsAccordionBody.displayName = 'RatingsAndReviewsAccordionBody';

RatingsAndReviewsAccordionBody.propTypes = RatingsAndReviewsPropTypes;

RatingsAndReviewsAccordionBody.defaultProps = RatingsAndReviewsDefaultProps;

RatingsAndReviewsAccordionBody.dataModel = dataModel;

const HydratedComponent = withHydrator({
  id: 'product-section-ratings-reviews',
  scrollBuffer: 750,
  delay: 5000,
  placeholder: (
    <div data-component="RatingsAndReviewsAccordionBodyPlaceholder">
      <Placeholder
        width="100%"
        height="1500px"
        color="#F5F5F5"
      />
    </div>
  ),
}, RatingsAndReviewsAccordionBody);
const DynamicComponent = withDynamicComponent(HydratedComponent);
const ErrorComponent = withErrorBoundary(DynamicComponent);

export { ErrorComponent as RatingsAndReviewsAccordionBody };
