import React, { useContext, useMemo, useCallback, useEffect } from 'react';
import {
  PodSection,
  PodSpacer,
  ProductImage,
  ProductPod,
  ProductRating,
} from '@thd-olt-component-react/product-pod';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import {
  string, bool, number, shape, arrayOf, func
} from 'prop-types';

import { ProductHeader } from '@thd-olt-component-react/product-details';
import { Price } from '@thd-olt-component-react/price';
import { Button, Col, Row } from '@thd-olt-component-react/core-ui';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { dataModel } from './SponsoredProductListingPod.dataModel';
import { useSponsoredProductPodTelemetry, useProductPodRedirectUrl, useNodeRef } from '../../../utils/hooks';
import { newRelicConstants } from '../../../constants/newRelicConstants';
import '../SponsoredProductListing.scss';

const plaPrefix = 'sponsored-pip-pla';

const SponsoredProductListingPod = (
  {
    product, storeId, position, noATCFulfillment
  }
) => {
  const experienceContext = useContext(ExperienceContext);
  // nodes
  const [productPodNodeRef, productPodNode] = useNodeRef();
  const [productPodRatingRef, productPodRatingNode] = useNodeRef();

  // --
  const { redirectUrl } = useProductPodRedirectUrl({
    canonicalUrl: product?.identifiers?.canonicalUrl
  });

  // --
  const isMobile = useMemo(
    () => experienceContext.channel === 'mobile',
    [experienceContext.channel]
  );

  // --
  const onclickBeacon = useMemo(() => {
    return product?.info?.sponsoredBeacon?.onClickBeacons?.[0];
  }, [product?.info?.sponsoredBeacon?.onClickBeacons]);

  // --
  const ctaBeacon = useMemo(() => {
    return onclickBeacon?.replace(/&redirect=$/, '');
  }, [onclickBeacon]);

  // -----------------
  // Telemetry
  // -----------------
  const {
    triggerClickOnShowNowButton,
    triggerClickOnImage,
    triggerClickOnHeader,
    triggerClickOnRating,
  } = useSponsoredProductPodTelemetry({
    productPodNode,
    itemId: product?.itemId,
    trackSource: product?.info?.sponsoredMetadata?.trackSource,
    position,
    slotId: product?.info?.sponsoredMetadata?.slotId,
    podKpiName: newRelicConstants.PRODUCT_LISTING_AD,
  });

  // -----------------
  // Handlers
  // -----------------

  /**
   * Handler on: show-now-button 'click'
   */
  const handleClickOnShowNowButton = useCallback(
    (evt) => {
      // --
      evt.preventDefault();
      triggerClickOnShowNowButton(); // new-relic
      fetch(ctaBeacon); // piq
      window.location = redirectUrl; // redirect
    },
    [triggerClickOnShowNowButton, redirectUrl, ctaBeacon]
  );

  /**
   * Handler on: image 'click'
   */
  const handleClickOnProductImage = useCallback(
    (event) => {
      event.preventDefault();
      // --
      triggerClickOnImage(); // new-relic
      window.location = redirectUrl; // redirect
    },
    [redirectUrl, triggerClickOnImage]
  );

  /**
   * Handler on: header 'click'
   */
  const handleClickOnProductHeader = useCallback(
    (event) => {
      event.preventDefault();
      // --
      triggerClickOnHeader(); // new-relic
      window.location = redirectUrl; // redirect
    },
    [redirectUrl, triggerClickOnHeader]
  );

  /**
   * Handler on: rating 'click'
   */
  useEffect(() => {
    // check
    if (!productPodRatingNode) return undefined; // case: not mounted
    if (!redirectUrl) return undefined;

    const selector = `a[href*="${product?.itemId}"]`;
    const element = productPodRatingNode.querySelector(selector);

    // check
    if (!element) return undefined;

    // --
    const handler = (event) => {
      event.preventDefault();
      // --
      triggerClickOnRating(); // new-relic
      window.location = redirectUrl; // redirect
    };

    // install
    element.addEventListener('click', handler);
    // uninstall
    return () => {
      element.removeEventListener('click', handler);
    };
  }, [productPodRatingNode, redirectUrl, product?.itemId, triggerClickOnRating]);

  return (
    <div
      ref={productPodNodeRef}
      data-product-id={product.itemId}
      className="sponsored-product-listing-pod"
      data-testid="sponsored-product-listing-pod"
    >
      <ImpressionProvider
        data={{
          id: '',
          name: 'SponsoredProductListing',
          component: 'SponsoredProductListing',
          type: 'sponsoredproduct',
          category: ''
        }}
      >
        <ProductPod
          itemId={product.itemId}
          analyticsData={{ parent: 'pla-on-pip', position: 0 }}
          render={(pod) => {
            return (
              <>
                <Row className={`${plaPrefix}-pod-column-grid`}>
                  <Col nopadding>
                    <div className={`${plaPrefix}-product-image-wrapper`}>
                      <ProductImage
                        itemId={pod.itemId}
                        showSecondaryImage={pod.showSecondaryImage}
                        onClick={handleClickOnProductImage}
                      />
                    </div>
                  </Col>
                  <Col nopadding>
                    <div className={`${plaPrefix}-product-content-wrapper`}>
                      <PodSection columnAlign alignTop noPadding>
                        <PodSpacer padding={['BOTTOM']}>
                          <ProductHeader
                            brand="above"
                            itemId={pod.itemId}
                            brandTitleMaxLine={3}
                            disableShopThisCollection
                            onClick={handleClickOnProductHeader}
                          />
                        </PodSpacer>
                        <div ref={productPodRatingRef}>
                          <ProductRating itemId={pod.itemId} />
                        </div>
                        <PodSpacer minHeight="60px">
                          <Price
                            itemId={pod.itemId}
                            large={false}
                            storeId={storeId}
                            displayEachUom={false}
                            onSavingsCenterToggle={pod.onSavingsCenterToggle}
                            hideBadge
                          />
                        </PodSpacer>
                        {!isMobile && (
                          <div className={`${plaPrefix}-shop-now-wrapper`}>
                            <PodSpacer padding={['TOP']}>
                              <Button outline onClick={handleClickOnShowNowButton}>
                                Shop Now
                              </Button>
                            </PodSpacer>
                          </div>
                        )}
                      </PodSection>
                    </div>
                  </Col>
                </Row>
              </>
            );
          }}
        />
      </ImpressionProvider>

    </div>
  );
};

SponsoredProductListingPod.propTypes = {
  product: shape({
    itemId: string.isRequired,
    identifiers: shape({
      canonicalUrl: string,
    }),
    info: shape({
      sponsoredBeacon: shape({
        onClickBeacons: arrayOf(string),
        onViewBeacons: arrayOf(string),
      }),
      sponsoredMetadata: shape({
        trackSource: string,
        campaignId: string,
        placementId: string,
        slotId: string,
        sponsoredId: string,
      }),
    }),
  }).isRequired,
  position: number.isRequired,
  noATCFulfillment: bool,
  storeId: string.isRequired,
};
SponsoredProductListingPod.defaultProps = {
  noATCFulfillment: null,
};

SponsoredProductListingPod.dataModel = dataModel;

export { SponsoredProductListingPod };
