import React, { useEffect, useContext } from 'react';
import { string } from 'prop-types';
import { Col, Row } from '@thd-olt-component-react/core-ui';
import { AccordionBodyPlaceholder } from './AccordionBodyPlaceholder';
import { QAResultContent } from '../QAResultContent/QAResultContent';
import { NoContent } from '../NoContent/NoContent.component';
import { useQAResult } from '../QAResult/useQAResult';
import { useSEO } from '../QAResult/useSEO';
import QAContext from '../../context/QAContext';
import { dataModel } from '../dataModel';
import { Pager } from '../Pager/Pager.component';
import { PagerDisplayText } from '../PagerDisplayText/PagerDisplayText.component';
import { triggerAnalytics } from '../../Analytics';
import '../QAResult/QAResult.scss';

const AccordionBodyComponent = ({ canonicalUrl }) => {
  useEffect(() => { triggerAnalytics('accordion-open'); }, []);

  let {
    channel, TotalResults, Results, searchText, qaLoading
  } = useContext(QAContext);

  const isMobile = channel === 'mobile';
  const qaResult = useQAResult();
  const {
    seoResults, seoTotalResults, seoAnswers
  } = useSEO(qaResult);

  if (qaLoading) return <AccordionBodyPlaceholder />;

  const noResults = !seoAnswers && (TotalResults === 0 || Results?.length === 0) && !searchText;
  let url = '#';

  if (canonicalUrl) {
    const [withoutQuery] = canonicalUrl.split('?');
    const path = withoutQuery.replace('/p', '');
    url = `/p/questions${path}/1`;
  }

  if (seoResults) {
    Results = seoResults;
    TotalResults = seoTotalResults;
  }

  return (
    <>
      {noResults
        ? (
          <>
            <NoContent />
          </>
        )
        : (
          <>
            <QAResultContent
              seoResults={seoResults}
              seoAnswers={seoAnswers}
              seoTotalResults={seoTotalResults}
            />
            <Row>
              <Col fallback="12" className="pager-section pager-section--mobile">
                <div className="q-a__review-pager">
                  <Pager seoTotalResults={TotalResults} />
                </div>
                <Row className="q-a__review-pager__summary">
                  <PagerDisplayText topPager={false} loading={qaLoading} seoTotalResults={TotalResults} />
                </Row>
              </Col>
            </Row>
          </>
        )}
    </>
  );
};

AccordionBodyComponent.displayName = 'AccordionBody';

AccordionBodyComponent.propTypes = {
  canonicalUrl: string
};

AccordionBodyComponent.defaultProps = {
  canonicalUrl: null
};

AccordionBodyComponent.dataModel = dataModel;

export { AccordionBodyComponent };
