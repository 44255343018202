import React, { useReducer, useState } from 'react';
import { func, string, bool } from 'prop-types';
import { Col, Row } from '@thd-olt-component-react/grid';
import { Button, Image, Overlay, Loader } from '@thd-olt-component-react/core-ui';
import { QAField, DisplayName, EmailAddress, Footer } from './component/index';
import { triggerAnalytics } from '../../Analytics';
import { useSubmitAnswer, useSubmitQuestion } from '../../hooks/index';
import { notEmptyString, validateEmail } from './helper/QAModalFormHelper';
import { dataModel } from './dataModel';
import { ImageUpload } from './component/ImageUpload';
import './QAModalForm.scss';
import { DeviceDetection } from '../DeviceDetection/DeviceDetection';
import { QAModalFormSuccess } from './QAModalFormSuccess.component';

const initialState = {
  question: '',
  answer: '',
  displayName: '',
  email: '',
  images: [],
  displayNameErrorText: '',
  questionErrorText: '',
  answerErrorText: '',
  emailErrorText: ''
};

function reducer(state, { action, value }) {
  switch (action) {
  case 'question':
    return { ...state, question: value };
  case 'answer':
    return { ...state, answer: value };
  case 'displayName':
    return { ...state, displayName: value };
  case 'email':
    return { ...state, email: value };
  case 'questionError':
    return { ...state, questionErrorText: value };
  case 'answerError':
    return { ...state, answerErrorText: value };
  case 'displayNameError':
    return { ...state, displayNameErrorText: value };
  case 'emailError':
    return { ...state, emailErrorText: value };
  case 'images':
    return { ...state, images: value };
  default:
    return state;
  }
}

const QAModal = ({
  itemId,
  openModal,
  closeModal,
  headerText,
  questionText,
  userName,
  askedDate,
  channel,
  questionId
}) => {
  // Pre-populate email address for a signed in users
  initialState.email = window?.THDCustomer?.default?.email || '';
  const [deviceFingerPrint, setDeviceFingerPrint] = useState(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    question,
    answer,
    displayName,
    email,
    displayNameErrorText,
    questionErrorText,
    answerErrorText,
    emailErrorText,
    images
  } = state;
  const formInputClasses = 'qa-modal';
  const from = questionText ? 'answer' : 'question';
  const postQuestionData = {
    itemId,
    questionSummary: question,
    userNickName: displayName,
    userEmail: email,
    userId: window?.THDCustomer?.default?.userID || '',
    action: 'submit',
    sendEmailAlertWhenPublished: 'yes',
    deviceFingerPrint
  };

  const postAnswerData = {
    questionId,
    answerText: answer,
    userNickName: displayName,
    userEmail: email,
    userId: window?.THDCustomer?.default?.userID || '',
    action: 'submit',
    deviceFingerPrint,
    sendEmailAlertWhenPublished: 'yes'
  };

  const [submitQuestionForm, questionResponse] = useSubmitQuestion(postQuestionData);
  const [submitAnswerForm, answerResponse] = useSubmitAnswer(postAnswerData);

  const onSubmit = () => {
    dispatch({
      action: 'questionError',
      value:
        !notEmptyString(questionText) && !notEmptyString(state.question) && !state.question
          ? 'Please provide your question here.'
          : ''
    });
    dispatch({
      action: 'answerError',
      value:
        notEmptyString(questionText) && !notEmptyString(state.answer) && !state.answer
          ? 'Please provide your answer here.'
          : ''
    });
    dispatch({
      action: 'emailError',
      value: !notEmptyString(state.email) ? 'Please provide your email.' : ''
    });
    dispatch({
      action: 'displayNameError',
      value:
        !notEmptyString(state.displayName) && !state.displayName
          ? 'Please provide a display name.'
          : ''
    });
    if (
      validateEmail(state.email)
      && ((!notEmptyString(questionText) && notEmptyString(state.question))
        || (notEmptyString(questionText) && notEmptyString(state.answer)))
    ) {
      if (!notEmptyString(questionText)) {
        submitQuestionForm();
      } else {
        submitAnswerForm();
      }
    }

    const payload = {
      component: from === 'answer' ? 'answer a question overlay' : 'ask a question overlay',
      element: 'submit'
    };
    triggerAnalytics(payload);
  };

  const submittedSuccessfully = answerResponse?.data?.submitAnswer?.SubmissionId
    || questionResponse?.data?.submitQuestion?.SubmissionId;

  return (
    <Overlay onClose={closeModal} open={openModal} className="qa-modal-wrapper">
      <DeviceDetection onSuccess={(fingerprint) => setDeviceFingerPrint(fingerprint)} />
      {channel === 'mobile' && !submittedSuccessfully && (
        <Button
          link
          className="content-card__overlay-back content-card__overlay"
          onClick={closeModal}
          data-testid="QAModelOverlayMobile"
        >
          <span>
            <Image
              className="content-card__overlay-caret"
              src="https://assets.homedepot-static.com/images/v1/back-arrow-orange.svg"
              alt="Back"
              lazy
            />
          </span>
          Back
          <span className="col__11-12" />
        </Button>
      )}
      {submittedSuccessfully ? (
        <QAModalFormSuccess from={from} questionText={questionText} closeModal={closeModal} />
      ) : (
        <Col flatten className="qa-modal_content">
          <Col className="qa-modal__header" nopadding>
            <h2>{headerText}</h2>
            {channel !== 'mobile' && (
              <button type="button" onClick={closeModal} data-testid="QAModelOverlay">
                <Image
                  src="https://assets.homedepot-static.com/images/v1/close-symbol-orange.svg"
                  alt="Close"
                />
              </button>
            )}
          </Col>
          {/* QA Modal */}
          <div className="qa-modal">
            {questionText && (
              <Row className={formInputClasses}>
                <Col className={`qa-modal__review-text ${formInputClasses}`} nopadding>
                  <span className="qa-modal__question">Q: {questionText} </span>
                </Col>
                <Col className="asked-by" nopadding>
                  by {userName} <span className="asked-by__divider">|</span> {askedDate}
                </Col>
              </Row>
            )}
            <QAField
              formInputClasses={formInputClasses}
              text={questionText ? 'Answer' : 'Question'}
              placeholder={questionText ? 'Type your answer here' : 'Type your question here'}
              value={questionText ? answer : question}
              validationError={questionText ? answerErrorText : questionErrorText}
              setQAText={(text) => {
                dispatch({ action: from, value: text });
                dispatch({ action: `${from}Error`, value: '' });
              }}
            />
            <ImageUpload
              formInputClasses={formInputClasses}
              images={images}
              setImages={(newImages) => {
                dispatch({ action: 'images', value: newImages });
              }}
              from={from}
            />
            <DisplayName
              formInputClasses={formInputClasses}
              displayName={displayName}
              validationError={displayNameErrorText}
              setDisplayName={(text) => {
                dispatch({ action: 'displayName', value: text });
                dispatch({ action: 'displayNameError', value: '' });
              }}
            />
            <EmailAddress
              formInputClasses={formInputClasses}
              emailAddress={email}
              validationError={emailErrorText}
              setEmailAddress={(text) => {
                dispatch({ action: 'email', value: text });
                dispatch({ action: 'emailError', value: '' });
              }}
            />
            <Footer formInputClasses={formInputClasses} from={from} onSubmit={onSubmit} />
          </div>
        </Col>
      )}
    </Overlay>
  );
};

QAModal.displayName = 'QAModal';

QAModal.propTypes = {
  itemId: string,
  openModal: bool,
  closeModal: func,
  headerText: string,
  questionText: string,
  userName: string,
  askedDate: string,
  channel: string,
  questionId: string
};

QAModal.defaultProps = {
  itemId: '',
  openModal: false,
  closeModal: null,
  headerText: '',
  questionText: '',
  userName: '',
  askedDate: '',
  channel: 'desktop',
  questionId: null
};

QAModal.dataModel = dataModel;

export { QAModal };
